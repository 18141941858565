.tile {
    display: block;
    padding: 10px 0px;
    text-align: center;
    color: #333;
    word-wrap: break-word;
}
.tile:hover {
    background: #e5f3ff;
    text-decoration: none;
    cursor: pointer;
    color: #333;
}
.tile-eye {
    position: absolute;
    top: 4px;
    left: 20px;
    display: none;
}
.tile:hover .tile-eye {
    display: inline;
}
.table-clickable .td-link {
    cursor: pointer;
}
.view-image-link {
    color: #000;
}