button.sendenBtn, .map_canvas .gm-style .form button.sendenBtn, .btnLook, article ul li:before, #directLinks ul li:before, .zoom, .calWrap .event_2, .calHead, .boxWrap .boxHead, .linkList li:before, .moreBtn a, .pagerJumper a.aktiv, #wToday, .campaignList .campaingLabel {
	background-color:#0093d2;
}
.map_canvas .gm-style .form button.sendenBtn:hover {
	color:#ffffff;
}
article a, aside a, #directLinks a:hover, #breadcrumb a, .form  a, .more, .optsContainer .duty, .pages a.enabled, .colored, h1, h2, aside h3, aside h4, .calWrap a, .form fieldset legend, #progressbar span, #wMisc .wMiscHead, .boxContent button.sendenBtn:hover {
	color:#0093d2;
}
#progressbar span { border-color:#f1fafd; }

.optsContainer input, .optsContainer select, .optsContainer textarea, .linkList li, #progressbar span.aktiv, #teaserWrap {
	border-color:#0093d2;
}
::-moz-selection {
    background: #0093d2;
}
::selection {
    background: #0093d2;
}
.optsContainer .optsBlockInline label.aktiv {
	background-color:#0093d2 !important;
}

.stdTable .oddLine, .stdTable tr:nth-child(odd) td, .list > li:nth-child(odd) { background-color:#ffffff; }
.stdTable .evenLine, .stdTable tr:nth-child(even) td, .list > li:nth-child(even) { background-color:#f1fafd; }
.stdTable .thead, .stdTable th { color:#0093d2; }
.calHead a, .calWrap .event_2 a, .boxWrap .boxHead, .imgWrap, .boxWrap, .imgWrap a, .boxWrap a, .moreBtn a, .boxWrap .disabled, .boxWrap .missing, #wToday, .campaignList .campaingLabel { color:#ffffff; }
.boxWrap .colorBar, .boxWrap li:before { background-color:#ffffff; }
.calWrap .event_1, .optsContainer .optsBlockInline label:hover { background-color:#f1fafd; }
.imgWrap, .boxWrap { background-color:#33a9db; }
.optsSecond, .pagerJumper a { background-color:#f1fafd; }

.paginator-pager .paginator-page { background-color:#26a3d9; }
.paginator-pager .paginator-page.aktiv { background-color:#0093d2; }




#navMain li.cs1, .boxWrap.cs1 .boxHead, #navMain li.cs1 li a:before, #navMain li.cs1 a.aktiv, .cs1 ul li:before { background-color:#0093d2; }
.boxWrap.cs1 { background-color:#33a9db; }
#navMain li.cs1 ul { background-color:#f1fafd; }
#navMain li.cs1 a, .gridList .cs1 .imgWrap { border-color:#26a3d9; }
#navMain li.cs1 a:hover, #navMain li.cs1 li li a.aktiv { background-color:#26a3d9; }
#navMain li.cs1 li a:hover:before, #navMain li.cs1 li a.aktiv:before { background-color:#ffffff; }
.boxWrap.cs1 .colorBar { background-color:#ffffff; }
#navMain li.cs1 a.lvl1, #navMain li.cs1 a.aktiv, .boxWrap.cs1 .boxHead { color:#ffffff; }
.gridList .cs1 .boxTextWrap strong { color:#0093d2; }
option.cs1 { background-color:#0093d2; color:#ffffff }
.cs1 h1, .cs1 h2, .cs1 a, .cs1 .colored { color:#0093d2; }
#navMain li.cs2, .boxWrap.cs2 .boxHead, #navMain li.cs2 li a:before, #navMain li.cs2 a.aktiv, .cs2 ul li:before { background-color:#73cbef; }
.boxWrap.cs2 { background-color:#73cbef; }
#navMain li.cs2 ul { background-color:#f1fafd; }
#navMain li.cs2 a, .gridList .cs2 .imgWrap { border-color:#8fd5f2; }
#navMain li.cs2 a:hover, #navMain li.cs2 li li a.aktiv { background-color:#8fd5f2; }
#navMain li.cs2 li a:hover:before, #navMain li.cs2 li a.aktiv:before { background-color:#ffffff; }
.boxWrap.cs2 .colorBar { background-color:#73cbef; }
#navMain li.cs2 a.lvl1, #navMain li.cs2 a.aktiv, .boxWrap.cs2 .boxHead { color:#ffffff; }
.gridList .cs2 .boxTextWrap strong { color:#73cbef; }
option.cs2 { background-color:#73cbef; color:#ffffff }
.cs2 h1, .cs2 h2, .cs2 a, .cs2 .colored { color:#73cbef; }
#navMain li.cs3, .boxWrap.cs3 .boxHead, #navMain li.cs3 li a:before, #navMain li.cs3 a.aktiv, .cs3 ul li:before { background-color:#b1ca35; }
.boxWrap.cs3 { background-color:#c1d55d; }
#navMain li.cs3 ul { background-color:#f1fafd; }
#navMain li.cs3 a, .gridList .cs3 .imgWrap { border-color:#bdd253; }
#navMain li.cs3 a:hover, #navMain li.cs3 li li a.aktiv { background-color:#bdd253; }
#navMain li.cs3 li a:hover:before, #navMain li.cs3 li a.aktiv:before { background-color:#ffffff; }
.boxWrap.cs3 .colorBar { background-color:#ffffff; }
#navMain li.cs3 a.lvl1, #navMain li.cs3 a.aktiv, .boxWrap.cs3 .boxHead { color:#ffffff; }
.gridList .cs3 .boxTextWrap strong { color:#b1ca35; }
option.cs3 { background-color:#b1ca35; color:#ffffff }
.cs3 h1, .cs3 h2, .cs3 a, .cs3 .colored { color:#b1ca35; }
#navMain li.cs4, .boxWrap.cs4 .boxHead, #navMain li.cs4 li a:before, #navMain li.cs4 a.aktiv, .cs4 ul li:before { background-color:#f6a800; }
.boxWrap.cs4 { background-color:#f8b933; }
#navMain li.cs4 ul { background-color:#f1fafd; }
#navMain li.cs4 a, .gridList .cs4 .imgWrap { border-color:#f7b526; }
#navMain li.cs4 a:hover, #navMain li.cs4 li li a.aktiv { background-color:#f7b526; }
#navMain li.cs4 li a:hover:before, #navMain li.cs4 li a.aktiv:before { background-color:#ffffff; }
.boxWrap.cs4 .colorBar { background-color:#ffffff; }
#navMain li.cs4 a.lvl1, #navMain li.cs4 a.aktiv, .boxWrap.cs4 .boxHead { color:#ffffff; }
.gridList .cs4 .boxTextWrap strong { color:#f6a800; }
option.cs4 { background-color:#f6a800; color:#ffffff }
.cs4 h1, .cs4 h2, .cs4 a, .cs4 .colored { color:#f6a800; }
#navMain li.cs5, .boxWrap.cs5 .boxHead, #navMain li.cs5 li a:before, #navMain li.cs5 a.aktiv, .cs5 ul li:before { background-color:#0093d2; }
.boxWrap.cs5 { background-color:#33a9db; }
#navMain li.cs5 ul { background-color:#f1fafd; }
#navMain li.cs5 a, .gridList .cs5 .imgWrap { border-color:#26a3d9; }
#navMain li.cs5 a:hover, #navMain li.cs5 li li a.aktiv { background-color:#26a3d9; }
#navMain li.cs5 li a:hover:before, #navMain li.cs5 li a.aktiv:before { background-color:#ffffff; }
.boxWrap.cs5 .colorBar { background-color:#0093d2; }
#navMain li.cs5 a.lvl1, #navMain li.cs5 a.aktiv, .boxWrap.cs5 .boxHead { color:#ffffff; }
.gridList .cs5 .boxTextWrap strong { color:#0093d2; }
option.cs5 { background-color:#0093d2; color:#ffffff }
.cs5 h1, .cs5 h2, .cs5 a, .cs5 .colored { color:#0093d2; }
.ui-widget-content .season_1 .ui-state-default { background:#e1d2a4; color:#333; border-color:#e1d2a4; }
.ui-widget-content .season_1 .ui-state-hover { border-color:#333; }
.ui-widget-content .season_1 .ui-state-active { font-weight:bold; background:#333; color:#FFF; border-color:#333; }
.ui-widget-content .season_2 .ui-state-default { background:#ffdfe3; color:#333; border-color:#ffdfe3; }
.ui-widget-content .season_2 .ui-state-hover { border-color:#333; }
.ui-widget-content .season_2 .ui-state-active { font-weight:bold; background:#333; color:#FFF; border-color:#333; }
.ui-widget-content .season_3 .ui-state-default { background:#c5edd5; color:#333; border-color:#c5edd5; }
.ui-widget-content .season_3 .ui-state-hover { border-color:#333; }
.ui-widget-content .season_3 .ui-state-active { font-weight:bold; background:#333; color:#FFF; border-color:#333; }
.ui-widget-content .season_4 .ui-state-default { background:#ffff99; color:#333; border-color:#ffff99; }
.ui-widget-content .season_4 .ui-state-hover { border-color:#333; }
.ui-widget-content .season_4 .ui-state-active { font-weight:bold; background:#333; color:#FFF; border-color:#333; }
.ui-widget-content .season_5 .ui-state-default { background:#ffffff; color:#333; border-color:#ffffff; }
.ui-widget-content .season_5 .ui-state-hover { border-color:#333; }
.ui-widget-content .season_5 .ui-state-active { font-weight:bold; background:#333; color:#FFF; border-color:#333; }