/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */

/* ==========================================================================
   Chrome Frame prompt
   ========================================================================== */

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
.buttonHasnWurst { background-color:#666; }
   
/* ==========================================================================
  	Variable Grid System.
	Learn more ~ http://www.spry-soft.com/grids/
	Based on 960 Grid System - http://960.gs/

	Licensed under GPL and MIT.
   ========================================================================== */

/* Containers */
.container_12 {
	margin-left: auto;
	margin-right: auto;
	width: 960px;
}
.gridWrapper {
	margin-left: -15px;
	margin-right: -15px;
}

/* Grid >> Global */
.grid_1, .grid_2, .grid_3, .grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
	display:inline;
	float: left;
	position: relative;
	margin-left: 15px;
	margin-right: 15px;
}
.push_1, .pull_1, .push_2, .pull_2, .push_3, .pull_3, .push_4, .pull_4, .push_5, .pull_5, .push_6, .pull_6, .push_7, .pull_7, .push_8, .pull_8, .push_9, .pull_9, .push_10, .pull_10, .push_11, .pull_11 { position:relative; }

/* Grid >> Children (Alpha ~ First, Omega ~ Last) */
.alpha { margin-left: 0; }
.omega { margin-right: 0; }

/* Grid >> 6 Columns */
.container_12 .grid_1 { width:50px; }
.container_12 .grid_2 { width:130px; }
.container_12 .grid_3 { width:210px; }
.container_12 .grid_4 { width:290px; }
.container_12 .grid_5 { width:370px; }
.container_12 .grid_6 { width:450px; }
.container_12 .grid_7 { width:530px; }
.container_12 .grid_8 { width:610px; }
.container_12 .grid_9 { width:690px; }
.container_12 .grid_10 { width:770px; }
.container_12 .grid_11 { width:850px; }
.container_12 .grid_12 { width:930px; }

/* Prefix Extra Space */
.container_12 .prefix_1 { padding-left:80px; }
.container_12 .prefix_2 { padding-left:160px; }
.container_12 .prefix_3 { padding-left:240px; }
.container_12 .prefix_4 { padding-left:320px; }
.container_12 .prefix_5 { padding-left:400px; }
.container_12 .prefix_6 { padding-left:480px; }
.container_12 .prefix_7 { padding-left:560px; }
.container_12 .prefix_8 { padding-left:640px; }
.container_12 .prefix_9 { padding-left:720px; }
.container_12 .prefix_10 { padding-left:800px; }
.container_12 .prefix_11 { padding-left:880px; }

/* Suffix Extra Space */
.container_12 .suffix_1 { padding-right:80px; }
.container_12 .suffix_2 { padding-right:160px; }
.container_12 .suffix_3 { padding-right:240px; }
.container_12 .suffix_4 { padding-right:320px; }
.container_12 .suffix_5 { padding-right:400px; }
.container_12 .suffix_6 { padding-right:480px; }
.container_12 .suffix_7 { padding-right:560px; }
.container_12 .suffix_8 { padding-right:640px; }
.container_12 .suffix_9 { padding-right:720px; }
.container_12 .suffix_10 { padding-right:800px; }
.container_12 .suffix_11 { padding-right:880px; }

/* Push Space */
.container_12 .push_1 { left:80px; }
.container_12 .push_2 { left:160px; }
.container_12 .push_3 { left:240px; }
.container_12 .push_4 { left:320px; }
.container_12 .push_5 { left:400px; }
.container_12 .push_6 { left:480px; }
.container_12 .push_7 { left:560px; }
.container_12 .push_8 { left:640px; }
.container_12 .push_9 { left:720px; }
.container_12 .push_10 { left:800px; }
.container_12 .push_11 { left:880px; }

/* Pull Space */
.container_12 .pull_1 { left:-80px; }
.container_12 .pull_2 { left:-160px; }
.container_12 .pull_3 { left:-240px; }
.container_12 .pull_4 { left:-320px; }
.container_12 .pull_5 { left:-400px; }
.container_12 .pull_6 { left:-480px; }
.container_12 .pull_7 { left:-560px; }
.container_12 .pull_8 { left:-640px; }
.container_12 .pull_9 { left:-720px; }
.container_12 .pull_10 { left:-800px; }
.container_12 .pull_11 { left:-880px; }

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
#teaserWrapMin, #teaserWrapMax { margin:0 auto; }
#teaserWrapMin {
	width:100%;
	overflow:hidden;
	min-width:960px;
	position:relative;
}
#teaserWrapMax {
	max-width:1500px;
}
#teaserWrap:after, #teaserWrap:before {
	content:'';
	background:url(../img/teaserSmoothEdges.png) repeat-y;
	position:absolute;
	width:50px;
	top:0;
	bottom:-10px;
	display:block;
	z-index:4;
}
#teaserWrap:after { left:0px; background-position:-50px 0; }
#teaserWrap:before { right:0px; background-position:0 0; }

#teaserWrap {
	margin:0 -50px;
	position:relative;
	border-bottom:10px solid #a3a3a3;
}
#teaser {
	width:100%;
	height:0;
	padding-bottom:300px;
	background:#FFF;
}
#teaser ul {
    list-style: none outside none;
    margin: 0;
    padding: 0;
}
#teaser li {
    display: block;
    left: 0;
    position: absolute;
    top: 0;
	width:100%;
}
#teaser img { width:100%; height:300px; display:block; }


#teaser:before { 
	content: "";
	position: absolute;
	bottom: -10px;
	left: 10px;
	right: 10px;
	display:block;
	height: 10px;
	z-index: 3;
	-webkit-box-shadow: 0 0 10px 1px rgba(0,0,0,1);
	   -moz-box-shadow: 0 0 10px 1px rgba(0,0,0,1);
	        box-shadow: 0 0 10px 1px rgba(0,0,0,1);
}

#topWrap {
	z-index:10;
	position:absolute;
	top:0;
	width:100%;
	left:0;
	right:0;
	z-index:10;
}
#topWrap.fixed {
	position:fixed;
	margin-top:-50px;
	z-index:9;
}
#top {
	background:#FFF;
	-webkit-box-shadow:0 0 5px rgba(0,0,0,0.5);
	   -moz-box-shadow:0 0 5px rgba(0,0,0,0.5);
	        box-shadow:0 0 5px rgba(0,0,0,0.5);
}
#top a { color:#333; }

#wrapper, footer { margin-bottom:15px; }


/* STD NAV */
#navStd a, #navMain a, #top a { text-decoration:none; }

#navStd .nav-collapse { position:relative; } 

#navStd ul, #navMain ul { margin:0; padding:0; list-style:none; }
#navStd ul { text-align:right; }
#navStd li { display:inline; }
#navStd li a {
	font-weight:normal;
	min-height:50px;
	line-height:40px;
	display:inline-block;
	font-size:12px;
	text-transform:uppercase;
}
#navStd li a.aktiv { font-weight:bold; }
#navStd li.aktiv a, #navStd li:hover a { background-color:#eee; }

#navStd li a span {
	display:block;
	padding:5px 0;
	margin:0 10px;
}

#navStd li .nav-trigger {
	position:relative;
	z-index:1;
	padding:17px 0;
	margin-left:-10px;
	display:inline-block;
	vertical-align:-21px;
}
#navStd li .nav-trigger .sprt {
	display:block;
}
#navStd li .nav-sub { display:none; }
#navStd li .expand { display:block; }

/* NAV MAIN */
#navMain { margin:0; background:url(../img/mainNavBG.gif) repeat center top; padding-top:10px; }
#navMain a { padding:8px 10px; display:block; color:#333; font-weight:bold; font-style:italic; font-size:14px; line-height:1.714; border-bottom:5px solid #CCC; }
#navMain li li + li { border-top:1px solid #FFF; }
#navMain li li a { border:none; color:#333; font-weight:normal; padding:10px 10px 9px 30px; line-height:1.428; position:relative; }
#navMain li li a:before { width:10px; height:10px; display:block; content:''; background:#FFF; position:absolute; left:10px; top:15px; }
#navMain li li li { border-top:none; }
#navMain li li li a { padding:5px 10px 5px 30px; font-size:12px; font-style:normal; }
#navMain li li li li a { padding-left:45px; }
#navMain li li li a:before { display:none; }
#navMain a:hover { background:#222; color:#fff; }

/* SUCHE */
#src { background:#eee; position:relative; height:50px; }
#src label { display:none; }
#src #srcBoxQ {
	-webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
	        box-sizing: border-box;
    background: none;
    border: 0 none;
    bottom: 0;
    color: #333;
    display: block;
    height: 50px;
    left: 0;
    line-height: 20px;
    padding: 15px 50px 15px 10px;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0;
}
#src button {
	position:absolute;
	right:10px;
	top:10px;
	border:0;
	background:#555;
	margin:0;
	padding:0;
	-webkit-box-shadow: 0 0 3px rgba(0,0,0,0.75);
	   -moz-box-shadow: 0 0 3px rgba(0,0,0,0.75);
	        box-shadow: 0 0 3px rgba(0,0,0,0.75);
}
#src button:hover { background:#333; }
#src button .sprt { margin:-1px; display:block; }

/* LANGUAGE */
#lng { float:left; }
#lng .ident { display:none; }
#lng a { line-height:50px; height:50px; display:block; float:left; padding:0 10px; text-transform:uppercase; }
#lng a.aktiv, #lng a:hover { background:#eee; }
#lng img { vertical-align:-1px; padding-right:5px; width:16px; height:11px; }

/* FONT-SIZE */
#fs { float:right; }
#fs ul {
	margin:0;
	padding:0;
	list-style:none;
}
#fs li { float:left; }
#fs a { line-height:50px; height:50px; display:block; padding:0 10px; }
#fs a.aktiv, #fs a:hover { background:#eee; }

/* LOGO & PILLARS */
#logo-nav-schadow {
	left:50%;
	margin-left:-465px;
	width:210px;
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,0.5);
	   -moz-box-shadow: 0 0 5px rgba(0,0,0,0.5);
	        box-shadow: 0 0 5px rgba(0,0,0,0.5);
	height:105px;
	position:absolute;
	bottom:10px;
	z-index:3;
}
#logo-nav {
	width:210px;
	background:#FFF;
	margin-top:-115px;
	min-height:115px;
	z-index:3;
}
#logo { display:block; }
#logo img { display:block; height:105px; }

#mascot {
	position:absolute;
	left:15px;
	bottom:-35px;
}
#lslLogo {
	position:absolute;
	left:15px;
	bottom: 0px;
}
#mascot,
#mascot img {
	width:210px;
	height:250px;
	display:block;
}
#lslLogo,
#lslLogo img {
	width:210px;
	height:102px;
	display:block;
}
.hasMascot,
.hasLslLogo {
	position:relative;
}
.hasMascot #logo-nav { 
	margin-bottom:230px;
}
.hasLslLogo #logo-nav {
	margin-bottom:117px;
}


/* BROTKRUMENPFAD */
#breadcrumb {
	border-bottom:1px solid #f2f2f2;
	color: #888;
	font-style: italic;
	padding:0.41667em 0;
}
#breadcrumb span {
	padding:0 0.25em;	
}
#breadcrumb a { text-decoration:none; }
#breadcrumb a:last-child { font-weight:bold; }

/* FOOTER */
footer {
	background:#b9b9b9;
	color:#FFF;
}
footer * { color:#FFF; }

footer table { width:100%; }
#zvlsb { background:#dbdbdb; margin:0; padding:0; width:240px; float:left; }
#zvlsb a { text-align:left;  display:block; }

#ftMisc { margin-top:18px; margin-bottom:0px; }
#ftContact { margin-top:14px; margin-bottom:0px; }

#ftMisc { height:32px; }
#ftMisc ul { margin:0; padding:0; list-style:none; position:absolute; right:0; }
#ftMisc li { display:block; margin-left:12px; float:left; }
#ftMisc li:first-child { margin-left:0; }

/* SHAREIFF */
#shariff {
	margin: 1em 0;
}

/* ==========================================================================
   Modules
   ========================================================================== */

/* CONTENTS */
article { margin:0.833em 0; }
article > h1, article > h2, article > h3, .first { margin-top:0; }
article .boxWrap { width:210px; }
article .linkList { margin:0 10px; }
article .boxWrap li { border-top-width:1px; border-top-style:solid; margin-left:0; }
article .boxWrap li:first-child { border:none; }
article .boxWrap + .boxWrap { margin-top: 20px; }

/*
article ul { margin:0; padding:0; list-style:none; }
article li { margin:0 0 0 0.833em; padding:0.333em 0 0.333em 20px; position:relative; }
article ul li:before, #directLinks ul li:before { content:''; width:0.833em; height:0.833em; display:block; position:absolute; left:0; top:0.667em; background:#333; }
article.noMargin, hr.noMargin { margin:0; }
*/

article .articleWrap p:last-child { margin-bottom:0; }

/* IFRAME CONTENT */
#fancy_frame { margin-left:15px; margin-right:15px; }

/* BOOK LISTS */
.listing article.hr~article.hr { border-top:1px solid #f2f2f2; }

/* START */
.newsBlock h2, .eventsBlock h2 { margin-bottom:0.1667em; margin-top:0.3889em; }
.newsBlock h4, .newsBlock a, .eventsBlock a {
	font-size:1em;
	font-weight:bold;
	color:#333;
	text-decoration:none;
}
.newsBlock h4 { margin:0.5em 0; }
.eventsBlock .list { margin-top:0; }
.youtube img,
.imgStart img {
    height: auto;
    width: 100%;
	display: block;
}
aside h2,
.aside h2 {
	margin-bottom:0;
}
aside .boxWrap,
.aside .boxWrap {
	margin-top: 20px;
}
aside .boxWrap:first-child,
.aside .boxWrap:first-child {
	margin-top:10px;
}
#directLinks ul { margin:0; padding:0; list-style:none; }
#directLinks li { padding-left:20px; line-height:20px; position:relative; }
#directLinks li + li { border-top:1px solid #e5e5e5; }
#directLinks li:before { top:0.75em; }
#directLinks a { color:#333; display:block; padding:5px 0; }

.boxMargin {
	margin: 10px 0;
}

#weather { padding:5px; background:no-repeat right top; }
#weather * { font-size:10px; line-height:15px; }
#wToday { display:inline-block; padding:0 5px; max-width:75px; }
#wToday span { display:block; }
#wMisc { margin-top:10px; }
#wMisc .wMiscHead { font-size:1.2em; display:block; }
#wMisc .sprt { float:left; margin-right:5px; }
#wWind { width:120px; margin-right:5px; float:left; }
#wRain .wMiscData { line-height:30px; }
#waterInfo { padding-top:5px; }

/* SERACH */
.search_results h3 { margin:0; }
.search_results .depthpath { color:#888; font-style:italic; }
.search_results p { margin-bottom:0; }

/* EVENTS */
.event h2 { margin-top:0; }
.eventData { text-align:right; padding:0.5em 0; }
.eventData p { margin:0 0 0.5em 0; font-size:0.91667em; line-height:1.5; }
.eventData .dateText { font-weight:bold; }
.eventInfo h3 { margin-top:0; }
.eventInfo p { margin:0 0 0.5em 0; }
.eventInfo small { font-weight:normal; }

#calSrc { margin:1em 0 2em; }
.calWrap { position:relative; margin:0.5em 0; }
.calWrap table { width:100%; margin:0; border-collapse:separate; position:relative; }
.calWrap td { text-align:center; border:1px solid #f2f2f2; border-width:1px 0 0; padding:0.25em 0; }
.calWrap td, .calWrap td, .calWrap th { font-size:1em; }
.calWrap th { font-weight:normal; text-align:center; padding:0.25em 0; }
.calWrap td.choosenDate { font-weight:bold; }
.calWrap .event_2 { color:#FFF; }
.calWrap .event_2 a, .calWrap .choosenDate a { color:#FFF; }
.calWrap td.today a { font-weight:bold; }

.calHead td { text-align:center; color:#FFF; border:none; }
.calHead .past, .calHead .later { width:25%; }
.calHead .past a, .calHead .later a { display:block; font-weight:bold; font-style:normal; }
.calHead .current { width:50%; }

.calWrap .calLoadBG { background:#FFFFFF; position:absolute; top:0; bottom:0; left:0; width:100%; z-index:1; }
.calWrap .calLoader { background:url(../img/loading.gif) no-repeat center center; position:absolute; top:0; bottom:0; left:0; width:100%; z-index:2; }

.calForm fieldset { margin:1em 0; }
.calForm legend {  font-size:1.4em; line-height:1.35em; margin-bottom:0.5em; font-weight:bold; }

.headingMore { float:right; margin-top:1.25em; display:block; }

/* SEASONS */
.calMonthsWrap { margin:-15px -15px 0 -15px; }
.calMonth { float:left; margin:0; width:33.3%; height:19em; }
.calMonth .calHead { font-size:1.2em; background:none !important; }
.calMonthInner {  margin:15px; }
.calMonth table { width:100%; border-spacing:1px; border-collapse:separate; table-layout:fixed; }
.calMonth td, .calMonth th { text-align:center; padding:0.1em 0; }
.calMonth th { border:1px solid #f2f2f2; border-width:1px 0; }
.calMonth .today { font-weight:bold; font-style:italic; }

.calLegend { display: table; margin:0; padding:0; list-style:none; width:100%; }
.calLegend li { display: table-cell; text-align: left; }
.calLegend li span { display:inline-block; width:2em; height:2em; margin-right:5px; }

/* NEWSLETTER */
.letterThemesList .desc { font-size:0.833em; margin-top:0; line-height:1.5; }

/* ==========================================================================
   external Scripts/Functions
   ========================================================================== */

/* jQuery Carousel version 0.1.6 by Michael Lehmann */
.vc_carousel_pages { position:absolute; right:50px; top:0; z-index:2; }
.vc_carousel_sprite { background:url(../img/jQuery_vc_carousel/vc_carousel_sprite.png) no-repeat 0 0; }
.vc_carousel_button {
	margin:0;
	padding:0;
	border:none;
	cursor:pointer;
	display:block;
	z-index:2;
}
.vc_carousel_page {
	background-position:0 0;
	width:24px;
	height:24px;
	float:left;
}

.vc_carousel_next, .vc_carousel_prev  { width:48px; height:48px; position:absolute; top:50%; margin-top:-24px; }
.vc_carousel_next { background-position:-168px 0; right:0; }
.vc_carousel_prev { background-position:-24px 0; left:0; }
.vc_carousel_next:hover { background-position:-120px 0; }
.vc_carousel_prev:hover { background-position:-72px 0; }
.vc_carousel_button.aktiv { background-position:0 -24px; }
.vc_carousel_title_trigger { width:48px; height:48px; background-position:-216px 0; }
.vc_carousel_button span { display:none; }

.vc_carousel_title {
	position:absolute;
	right:50%;
	margin-right:-450px;
	bottom:30px;
	text-align:right;
	z-index:3;
	display:none;
	padding:0;
	min-height:48px;
}

.vc_carousel_title_trigger {
	position:absolute;
	right:0;
	top:0;
	cursor:pointer;
}

.vc_carousel_title_inner {
	top:20px;
	right:50px;
	position:relative;
	display:none;
}
.vc_carousel_title p, .vc_carousel_title p * {
	font-size:12px;
	line-height:14px;
	color:#fff;
	margin:0;
	padding:0;
	text-align:right;
}
.vc_carousel_title a:hover { text-decoration:underline; }

.vc_carousel_preloader {
	position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
	bottom:0;
	right:0;
	display:none;
	background:#1E1E1E url(../img/jQuery_vc_carousel/loadingDark.gif) no-repeat center center;
	color:#FFFFFF;
	z-index:3;
	overflow:hidden;
}
.vc_carousel_preloader span {
	position:absolute;
	top:50%;
	left:50%;
	margin:16px -100px;
	display:block;
	width:200px;
	font-size:18px;
	line-height:24px;
	font-style:italic;
	text-align:center;
}
.js .vc_carousel_preloader { display:block; }

/* PRETTY PHOTO */
div.facebook .pp_top .pp_left{background:url(../img/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat}
div.facebook .pp_top .pp_middle{background:url(../img/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x}
div.facebook .pp_top .pp_right{background:url(../img/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat}
div.facebook .pp_content_container .pp_left{background:url(../img/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y}
div.facebook .pp_content_container .pp_right{background:url(../img/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y}
div.facebook .pp_expand{background:url(../img/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;cursor:pointer}
div.facebook .pp_expand:hover{background:url(../img/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;cursor:pointer}
div.facebook .pp_contract{background:url(../img/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;cursor:pointer}
div.facebook .pp_contract:hover{background:url(../img/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;cursor:pointer}
div.facebook .pp_close{width:22px;height:22px;background:url(../img/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;cursor:pointer}
div.facebook .pp_description{margin:0 37px 0 0; color:#222;}
div.facebook .pp_loaderIcon{background:url(../img/prettyPhoto/facebook/loader.gif) center center no-repeat}
div.facebook .pp_arrow_previous{background:url(../img/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;height:22px;margin-top:0;width:22px}
div.facebook .pp_arrow_previous.disabled{background-position:0 -96px;cursor:default}
div.facebook .pp_arrow_next{background:url(../img/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;height:22px;margin-top:0;width:22px}
div.facebook .pp_arrow_next.disabled{background-position:-32px -96px;cursor:default}
div.facebook .pp_nav{margin-top:0}
div.facebook .pp_nav p{font-size:15px;padding:0 3px 0 4px}
div.facebook .pp_nav .pp_play{background:url(../img/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;height:22px;width:22px}
div.facebook .pp_nav .pp_pause{background:url(../img/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;height:22px;width:22px}
div.facebook .pp_next:hover{background:url(../img/prettyPhoto/facebook/btnNext.png) center right no-repeat;cursor:pointer}
div.facebook .pp_previous:hover{background:url(../img/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;cursor:pointer}
div.facebook .pp_bottom .pp_left{background:url(../img/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat}
div.facebook .pp_bottom .pp_middle{background:url(../img/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x}
div.facebook .pp_bottom .pp_right{background:url(../img/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat}
div.pp_pic_holder a:focus{outline:none}
div.pp_overlay{background:#000;display:none;left:0;position:absolute;top:0;width:100%;z-index:9500}
div.pp_pic_holder{display:none;position:absolute;width:100px;z-index:10000}
.pp_content{height:40px;min-width:40px}
* html .pp_content{width:40px}
.pp_content_container{position:relative;text-align:left;width:100%}
.pp_content_container .pp_left{padding-left:20px}
.pp_content_container .pp_right{padding-right:20px}
.pp_content_container .pp_details{float:left;margin:10px 0 2px}
.pp_description{display:none;margin:0}
.pp_social{float:left;margin:0}
.pp_social .facebook{float:left;margin-left:5px;width:55px;overflow:hidden}
.pp_social .twitter{float:left}
.pp_nav{clear:right;float:left;margin:3px 10px 0 0}
.pp_nav p{float:left;white-space:nowrap;margin:2px 4px}
.pp_nav .pp_play,.pp_nav .pp_pause{float:left;margin-right:4px;text-indent:-10000px}
a.pp_arrow_previous,a.pp_arrow_next{display:block;float:left;height:15px;margin-top:3px;overflow:hidden;text-indent:-10000px;width:14px}
.pp_hoverContainer{position:absolute;top:0;width:100%;z-index:2000}
.pp_gallery{display:none;left:50%;margin-top:-50px;position:absolute;z-index:10000}
.pp_gallery div{float:left;overflow:hidden;position:relative}
.pp_gallery ul{float:left;height:35px;position:relative;white-space:nowrap;margin:0 0 0 5px;padding:0}
.pp_gallery ul a{border:1px rgba(0,0,0,0.5) solid;display:block;float:left;height:33px;overflow:hidden}
.pp_gallery ul a img{border:0}
.pp_gallery li{display:block;float:left;margin:0 5px 0 0;padding:0}
.pp_gallery li.default a{background:url(../img/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;display:block;height:33px;width:50px}
.pp_gallery .pp_arrow_previous,.pp_gallery .pp_arrow_next{margin-top:7px!important}
a.pp_next{background:url(../img/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;display:block;float:right;height:100%;text-indent:-10000px;width:49%}
a.pp_previous{background:url(../img/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;display:block;float:left;height:100%;text-indent:-10000px;width:49%}
a.pp_expand,a.pp_contract{cursor:pointer;display:none;height:20px;position:absolute;right:30px;text-indent:-10000px;top:10px;width:20px;z-index:20000}
a.pp_close{position:absolute;right:0;top:0;display:block;line-height:22px;text-indent:-10000px}
.pp_loaderIcon{display:block;height:24px;left:50%;position:absolute;top:50%;width:24px;margin:-12px 0 0 -12px}
#pp_full_res{line-height:1!important}
#pp_full_res .pp_inline{text-align:left}
#pp_full_res .pp_inline p{margin:0 0 15px}
div.ppt{color:#fff;display:none;font-size:17px;z-index:9999;margin:0 0 5px 15px}
div.facebook .pp_content .ppt,div.facebook #pp_full_res .pp_inline{color:#000}
.pp_gallery ul a:hover,.pp_gallery li.selected a{border-color:#fff}
div.facebook .pp_details{position:relative}
div.facebook .pp_content{background:#fff}
.pp_top,.pp_bottom{height:20px;position:relative}
* html .pp_top,* html .pp_bottom{padding:0 20px}
.pp_top .pp_left,.pp_bottom .pp_left{height:20px;left:0;position:absolute;width:20px}
.pp_top .pp_middle,.pp_bottom .pp_middle{height:20px;left:20px;position:absolute;right:20px}
* html .pp_top .pp_middle,* html .pp_bottom .pp_middle{left:0;position:static}
.pp_top .pp_right,.pp_bottom .pp_right{height:20px;left:auto;position:absolute;right:0;top:0;width:20px}
.pp_fade,.pp_gallery li.default a img{display:none}

/* GOOGLE MAPS */
.addrWrap { margin:0; clear:both; }

.map_canvas {
    font-family: Arial, Helvetica, sans-serif;
	height:30em;
	height:50vh;
}
.map_shorter {
	height:15em;
	height:25vh;
}
.map_canvas .gm-style {
	font-family: Arial, Helvetica, sans-serif;
	font-size:1em;
	color:#333;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}
.map_canvas .gm-style .form {
	margin-left: 0;
	margin-right: 0;	
}

.map_canvas .gm-style .gm-style-iw p {
	margin:0;
}
.map_canvas .gm-style .gm-style-iw p+p {
	margin-top:0.6667em;
}
.map_canvas .loadOverlay, .map_canvas .loadOverlay .loadingBG, .map_canvas .loadOverlay .loadingIcon {
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	top:0;
	z-index:1;
	width:100%;
	height:100%;
}
.map_canvas .loadOverlay .loadingIcon {
	background:url(../img/loading.gif) no-repeat center center;
}
.map_canvas .loadOverlay .loadingBG {
	background:#FFFFFF;
	opacity:0.5;
}

.map_canvas .mapDesc .mapTourTrigger {
	float: right;
}

.map_form .btnContainer {
	text-align:left;
}
.map_legend {
	margin-top:15px;
}
.map_legend .head {
	background: #72CAEE;
	cursor: pointer;
	color: #FFF;
	margin: 0;
	padding: 5px 15px;
}
.map_legend.aktiv .head {
	background: #0093D2;
}
.legendItemsWrap ul li:before {
	display:none;
}
.legendItemsWrap ul {
	margin:0 -15px;
	
}
.legendItemsWrap ul li {
	margin:0;
	padding:0;
	float:left;
	width:33.333%;
}
.legendItemsWrap ul li a {
	margin:5px 15px;
	display:block;
	vertical-align:middle;
}
.legendItemsWrap ul li:hover a, .legendItemsWrap ul li.aktiv a {
	background-color:#0093d2;
	color:#FFF;
}

/* VC PAGINATOR */
.paginator {
	position: relative;
	overflow: hidden;
}
.paginator .paginator-Page {
	position: absolute;
	left: 0;
	top: 0;
}
.paginator-pager {
	display: table;
	width: 100%;
	height: 5px;
	overflow: hidden;
	table-layout: fixed;
}
.paginator-pager .paginator-page {
	text-align: left;
	text-indent: -999em;
	overflow: hidden;
	display: table-cell;
	margin: 0;
	padding: 0;
	border: none;
	background: none;
	height: 5px;
	overflow: hidden;
	font-size: 1px;
	cursor: pointer;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Image replacement
 */

.ir {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    /* IE 6/7 fallback */
    *text-indent: -9999px;
}

.ir:before {
    content: "";
    display: block;
    width: 0;
    height: 100%;
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden, .versteckt {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 */

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}

/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */

.clearfix {
    *zoom: 1;
}

.clear {
	clear: both;
	display: block;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	width: 0;
}

.hint {
    color: #666;
    font-style: italic;
	font-weight: normal;
}
.missing {
    color: #666;
    font-style: italic;
	font-weight: normal;
	line-height: 1.2;
	margin-top:0.25em;
}
.disabled {
    color: #666;
}

.normal { 
	font-weight: normal;
}

.notiz_text {
    color: #666;
}

.fehler_text {
    color: #ca1820;
}

.success {
	font-style:italic;
	text-align:center;
	color:#0C0;
	font-weight:bold;
}

.dateText {
	color: #888;
	font-style: italic;
	white-space: nowrap;
}
.allowWordWrap {
	white-space:normal !important;
}


/* Kästchen */
.gridList article { margin-bottom:10px; margin-top:0; }
.gridList h2 { margin-bottom:5px; }
.gridList .imgWrap { border-bottom:5px solid #333; }
.gridList .imgWrap, .gridList .imgWrap img, .gridList .boxTextWrap strong { display:block; width:100%; }
.gridList .boxTextWrap { margin:5px 0 0; }
.gridList .boxTextWrap a { color:#333; }

/* BOXES */
.boxHead {
	background: none repeat scroll 0 0 #FFFFFF;
    color: #222222;
    display: block;
    font-size: 1.167em;
    font-style: italic;
    font-weight: bold;
    line-height: 1.143;
    padding: 7px 7px 17px;
    position: relative;
    text-decoration: none;
}
.boxHead .colorBar {
    bottom: 0;
    color: #FFFFFF;
    height: 10px;
    left: 0;
    line-height: 20px;
    overflow: hidden;
    padding-left: 40px;
    position: absolute;
    right: 40px;
    text-align: left;
}
.boxContent { margin:10px; }

/* LISTS */
.list { margin:0.833em 0; padding:0; list-style:none; }
.list li { padding:0.25em 0; }
.list li+li { border-top:1px solid #f2f2f2; }

.listItem { border-top: 1px solid #f2f2f2; margin: 0.5em 0; padding-top: 0.5em; }
.listItem:first-child { border-top: medium none; margin-top: 0; padding-top: 0; }
.listItem:last-child { margin-bottom: 0; }

/* BOXES */
.gridBox + .gridBox { margin-top:20px; }

/* VIDEOS */
.vid { position:relative; display:block; overflow:hidden; }
.vid .playIcon { position:absolute; top:50%; left:50%; margin:-22px 0 0 -38px; }
.vid:hover .playIcon { background-position:-76px -48px; }
.vid .video-time {
	position:absolute;
	background:#000;
	color:#FFF;
	display:block;
	padding:0.25em 0.5em;
	bottom:0;
	right:0;
	font-weight:bold;
}

/* TABELLEN */
.stdTable { width:100%; }
.stdTable + .stdTable { margin-top:0.833em; }
.stdTable th, .stdTable td { padding:5px; border-top: 1px solid #f2f2f2; }
.stdTable th, .stdTable .thead, .stdTable .thead td {
	font-style: normal;
	font-weight: bold;
	text-align: center;
	vertical-align: top;
}

/* Downloads */
.boxWrap .downloads { margin:10px 10px 0 10px; }
.download { margin-top:0; }
.download { padding:0; word-wrap: break-word; }
.download img { padding:0.25em 0.5em 0 0; float:left; display:block; }

/* Navi/Links */
.nav-trigger { cursor:pointer; }
.more { font-weight:normal; }
.moreBtn { text-align:right; float:right; }
.moreBtn a { padding:5px 10px; }
.moreBtn a:hover { background:#333; color:#FFF; }

/* Seitensprung & Blätterfunktion */
.jumper { margin:10px -10px; position:relative; height:40px; }
.jumper .resultStats, .pagerJumper .divider { display:none; }
.jumper .pagerJumper { display:block; margin:0 auto; position:absolute; right:0; }
.pagerJumper a {
	display:block;
	float:left;
	padding:4px;
	color:#333;
	font-weight:bold;
	margin:0 10px;
	width:32px;
	height:32px;
	line-height:32px;
	text-align:center; 
}
.pagerJumper a.aktiv { font-style:italic; text-decoration:underline; color:#fff; }

.pages { text-align:center; border-top:1px solid #f2f2f2; padding-top:0.5em; }
.pages a { color:#666; }
.pages a.enabled { font-weight:bold; }

/* Bildergalerie */
.imgWrap { position: relative; }
.extImgWrap img {
	display: block;
	width: 100%;
	height: auto;
}

.zoom {
	content:'+';
	color:#FFF;
	text-align:center;
	font-weight:bold;
    display: block;
    position: absolute;
	bottom: 0;
    right: 0;
	height: 30px;
	line-height:30px;
	font-size:24px;
    width: 30px;
}
.img:hover .zoom { background:#333; }

.gallery { margin:-15px; }

.imgContainer {
	float: left;
	margin: 15px;			
	position: relative;
}

.img {
	display:block;
	position: relative;
}
.caption { font-size:1em; line-height:1.5; display:block; padding:5px 10px; }

.imgLeft {
	float:left;
	margin:0 30px 10px 0;
}

.imgRight, .boxRight {
	float:right;
	margin:0 0 10px 30px;
	position: relative;
    z-index: 1;
}

.imgLeft .imgWrap + .imgWrap, .imgRight .imgWrap + .imgWrap {
	margin-top:10px;
}
.imgNone { margin-left:-15px; margin-right:-15px; }
.imgNone .left { float:left; margin-right:15px; margin-left:15px; }

.articleImgLeft { float:left; }
.articleImgLeft + .articleWrap { padding-left:30px; }
.articleImgRight { float:right; }
.articleImgRight + .articleWrap { padding-right:30px; }

/* CAMPAINGs */
.campaignList,
.campaignList li {
	margin: 0;
	padding: 0;
	border: none !important;
	list-style: none;
}
.campaignList li:before {
	display: none;
}
.campaignList .campaingImgFrame {
	display: block;
	position: relative;
}
.campaignList img {
	display: block;
}
.campaignList .campaingLabel {
	display: block;
	position: absolute;
	font-weight: bold;
	left: 0;
	top: 20px;
	font-size: 14px;
	padding: 5px 10px;
}

/* SPRITES */
.sprt, .sprtB:before, .sprtA:after {
	background:url('../img/sprt.png?v=2') no-repeat left top;
	display:inline-block;
}
.sprt { overflow:hidden; display:block; text-indent:-999em; text-align:left; }

.iconSmall-s { background-position:0 0; }
.iconSmall-n { background-position:-16px 0; }
.iconSmall-select { background-position:-80px 0; }
.iconSmall-date { background-position:-96px 0; }

.iconBackColored, .iconBackGrey:hover { background-position:-448px -16px; }
.iconBackGrey { background-position:-416px -16px; }
.iconBackward  { background-position:-192px -16px; }
.iconBig-s { background-position:0 -16px; }
.iconBig-n { background-position:-32px -16px; }
.iconBig-lupe { background-position:-64px -16px; }
.iconEdit { background-position:-128px -16px; }
.iconFacebookColored, .iconFacebookGrey:hover { background-position:-256px -16px; }
.iconFacebookGrey { background-position:-224px -16px; }
.iconFlickrColored, .iconFlickrGrey:hover { background-position:-320px -16px; }
.iconFlickrGrey { background-position:-288px -16px; }
.iconForward { background-position:-160px -16px; }
.iconPrintColored, .iconPrintGrey:hover { background-position:-512px -16px; }
.iconPrintGrey { background-position:-480px -16px; }
.iconRain { background-position:-192px -48px; }
.iconStar { background-position:-96px -16px; vertical-align:-8px; margin-left:-4px; }
.iconTour { background-position:-224px -48px; }
.iconTopColored, .iconTopGrey:hover { background-position:-384px -16px; }
.iconTopGrey { background-position:-352px -16px; }
.iconWind { background-position:-160px -48px; }

.iconSmall-date,
.iconSmall-n,
.iconSmall-s,
.iconSmall-select {
	width:16px; height:16px;
}

.iconBackColored,
.iconBackGrey,
.iconBackward,
.iconBig-n,
.iconBig-s,
.iconBig-lupe,
.iconEdit,
.iconFacebookColored,
.iconFacebookGrey,
.iconFlickrColored,
.iconFlickrGrey,
.iconForward,
.iconPrintColored,
.iconPrintGrey,
.iconRain,
.iconStar,
.iconTour,
.iconTopColored,
.iconTopGrey,
.iconWind {
	width:32px; height:32px;
}

.missing:before { background-position:-65px -1px; width:14px; height:14px; content:''; vertical-align:-3px; padding-right:2px; }
.playIcon { background-position: 0 -48px; width:76px; height:45px; }

/* Forms */
.form fieldset { border-top:1px solid #CCC; margin-top:15px; padding-top:15px; position:relative; }
.form fieldset legend {
    display: block;
    font-size: 15px;
    font-weight: bold;
	left: 20px;
    margin: 0;
    padding: 5px 5px 0;
	position: absolute;
    text-transform: uppercase;
	top: -18px;
	background:#FFF;
}
.form .duty { font-weight:bold; padding-right:5px; font-size:1.333em; line-height:1.25; }
.form .hint .duty { padding-right:0; font-size:1em; }
.optsContainer { margin:0; padding:0.833em 0; position:relative; }
.optsContainer + .optsContainer { border-top:1px solid #f2f2f2; }
.optsContainer label, label.optsLabel {
	display: block;
	float: left;
	line-height: 1.667;
	margin: 5px 30px 5px 0;
	text-align: right;
	font-style: italic;
	font-weight: bold;
}
.optsContainer label { width: 210px; }
.optsContainer:after { clear:both; content:' ';  display:table; }
.optsContainer label .more { font-style:italic; font-weight:bold; }
.optsContainer label .shortInfo, .optsNfo { display:block; font-size:10px; font-weight:normal; line-height:1.4; font-style:normal; }
.optsContainer input, .optsContainer select, .optsContainer textarea, .form input, .form select, .form textarea {
	border: 1px solid #666;
	padding: 5px;
	line-height: 1.667;
}
.optsButtonSet { padding-left:240px; margin:5px 0; }
.optsContainer + .optsNfo { padding-left:160px; margin-top:-12px; }
.optsContainer input, .optsContainer textarea { width:438px; }
.optsContainer select { width:450px; }
.optsContainer select option.hasCs { padding:1px 0; margin:1px 0; }

.boxContent .optsContainer,
.grid_3 .optsContainer,
.grid_6 .optsContainer {
	padding:0 0 5px 0;
}
.boxContent .optsContainer + .optsContainer,
.grid_3 .optsContainer + .optsContainer,
.grid_6 .optsContainer + .optsContainer {
	padding-top:5px;
	border:none;
}
.boxContent .optsContainer label,
.grid_3 .optsContainer label,
.grid_6 .optsContainer label {
	float:none; margin:0;
	text-align:left;
	width:auto;
}
.boxContent .optsContainer input,
.boxContent .optsContainer textarea,
.boxContent .optsContainer select,
.grid_3 .optsContainer input,
.grid_3 .optsContainer textarea,
.grid_3 .optsContainer select {
	width:100%;
	margin:0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.form input[type="radio"], .form input[type="checkbox"] { border:0 none; width:auto; }
.optsContainer input + label, .optsContainer .optsBlock label {
	display: inline-block;
	float: none;
	font-style: normal;
	font-weight: normal;
	margin: 0 12px 6px 0;
	padding: 5px 6px;
	text-align: left;
	width: auto;
	cursor:pointer;
}
.optsContainer .optsAlt label { margin:0; padding-left:0; padding-bottom:0; font-style:italic; }
.optsContainer .optsBlock { margin: 5px 0 5px 240px; }
.optsContainer .optsBlock input + label { display:inline; padding:0 0 0 5px; }
.optsRange .from label, .optsRange .to label { width:198px; padding:0 6px; margin:-5px 0 5px 0; font-style: normal; font-weight: normal; text-align: left; float:none; }
.optsContainer .optsBlockInline { margin-top:0; }
.optsContainer .optsBlockInline label.aktiv { color:#FFF; }
.optsContainer .optsBlockInline label:hover { color:#333; }
.optsContainer .optsBlockInline label img +span { padding-left:5px; }

.optsContainer .optsBlock .optsAltVisible + .optsLine {
	margin-top: 1em;
	
}

.optsContainer .date { position:relative; }
.optsContainer .date .sprt { position:absolute; right:6px; top:6px; cursor:pointer; }

.optsForCheck { font-weight:bold; padding:0.41667em 0; }
.optsForCheck label { font-weight:normal; }

.optsRange .from { margin-right:30px; }
.optsRange .from, .optsRange .to { width:210px; float:left; }
.optsRange .from input, .optsRange .to input { display:inline-block; width:198px; }
.optsRange > label { margin-top:25px; }

.optsText .optsBlock > :first-child { margin-top:5px; }
.optsText .optsBlock > :last-child { margin-bottom:5px; }

.optsBlock fieldset {
	 border:1px solid #f2f2f2;
	 padding:15px 5px 5px 5px;
	 background:#fafafa;
	 margin-bottom:5px;
	 min-height:30px;
}
.optsBlock fieldset legend { font-size:12px; }
.optsBlock .rangeList { margin:0; padding:0; list-style:none; display:inline-table; width:100%; table-layout:fixed; }
.optsBlock .rangeList li { display:table-cell; text-align:center; white-space:nowrap; font-size: 11px; }


.optsContainer .error { padding:0 0 5px 240px; color:#b00; }
.optsError { background-color:#fff2f2 !important; padding-top:5px; }

button.sendenBtn, .btnLook {
	border: none;
	color: #FFF;
	line-height: 2em;
	padding:0.5em 0;
	background:#ca1820;
	display:block;
	width:100%;
	margin-top:0.833em;
	font-weight:bold;
	cursor:pointer;
	text-align:center;
}
.btnLook {
	display:inline-block;
	width:auto;
	min-width:240px;
}
.btnContainer { margin:0 240px; }
.btnContainerLft { margin:0 480px 0 0; }
.btnContainerRght { margin:0 0 0 480px; }
.form button.sendenBtn:first-child { margin-top:0; }
button.sendenBtn:hover, .btnLook:hover { background-color:#333; color:#FFF; }
.grid_3 button.sendenBtn { padding:2px 0; }
.boxContent button.sendenBtn { background:#333; }
.boxContent button.sendenBtn:hover { background:#FFF; }

.form[disabled] button { background:#f0f0f0; color:#333; font-weight:normal; cursor:default; }

.form .ui-state-error { padding:0 0.8333em; }

.grid_6 select { width:450px; }
.grid_3 select { width:210px; }
.grid_6 input {width:438px; }

.ui-datepicker-trigger { height:27px; width:26px; padding-left:5px; cursor:pointer; }

/* BUTTONS, u.a. HolidayCheck*/
#wrapper div.hc-widget {
	font-family: inherit;
}

/* ==========================================================================
   STYLES FOR IE :/
   ========================================================================== */
.lt-ie8 #navStd li .nav-trigger {
	left:-10px;
	vertical-align:-17px;
}

.lt-ie9 #src #srcBoxQ {
	padding:10px 0;
	height:30px;
}

.lt-ie8 #topWrap { top:28px; }
.lt-ie8 #topWrap.fixed { top:0; }
.lt-ie8 #navMain li li a { padding-left:10px; }

.lt-ie8 .calLegend li { float:left; margin-right:30px; }

.lt-ie8 article ul, .lt-ie8 #directLinks ul { list-style-type:square; padding-left:20px; }
.lt-ie8 article li, .lt-ie8 #directLinks li { padding-left:0; }

.lt-ie8 #mascot { display:none; }

/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    /*a[href]:after {
        content: " (" attr(href) ")";
    }*/

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links for images, or javascript/internal links
     */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1.27cm;
    }
	article ul { margin:0; padding:0 0 0 20px; list-style:square; }
	article li { margin:0; padding:0.333em 0 0.333em 10px; }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
	#teaserWrapMin, #topWrap, footer, #logo-nav { display:none; }
	/* Containers */
	.container_12 { 
		width: 100%;
	}
	
	/* Grid >> Global */
	.grid_4, .grid_5, .grid_6, .grid_7, .grid_8, .grid_9, .grid_10, .grid_11, .grid_12 {
		width: 100%;
		margin-left:0;
		margin-right:0;
	}
}